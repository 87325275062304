<template>
  <section class="contact">
    <div class="container">
      <h2>What Can we do for you?</h2>
      <form 
        id="contact-form"
        @submit.prevent="processForm"
      >
        <div class="form-group">
          <label for="name">What’s your name? <span class="highlight">*</span></label>
          <input id="name" type="text" name="name" v-model="name" required>
        </div>
        <div class="form-group">
          <label for="email">An email to reach you <span class="highlight">*</span></label>
          <input id="email" type="email" name="email" v-model="email" required>
        </div>
        <div class="form-group">
          <label for="phone">How about a phone number?</label>
          <input id="phone" type="tel" name="phone" v-model="phone">
        </div>
        <div class="form-group">
          <h4>What do you need help with?</h4>
          <div class="checkbox-form-group">
            <label>
              Website
              <input type="checkbox" name="services[]" value="Website" v-model="services">
              <span class="checkmark"></span>
            </label>
            <label>
              IOS/Android App
              <input type="checkbox" name="services[]" value="IOS/Android App" v-model="services">
              <span class="checkmark"></span>
            </label>
            <label>
              Backend/Web Services
              <input type="checkbox" name="services[]" value="Backend/Web Services" v-model="services">
              <span class="checkmark"></span>
            </label>
            <label>
              Marketing
              <input type="checkbox" name="services[]" value="Marketing" v-model="services">
              <span class="checkmark"></span>
            </label>
            <label>
              Consultation
              <input type="checkbox" name="services[]" value="Consultation" v-model="services">
              <span class="checkmark"></span>
            </label>
            <label>
              Other
              <input type="checkbox" name="services[]" value="Other" v-model="services">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label for="message">Have a message?</label>
          <textarea id="message" type="text" name="message" rows="6" v-model="message">
          </textarea>
        </div>
        <button type="submit" class="submit-btn">Submit</button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      message: null,
      services: [],
    }
  },
  methods: {
    processForm() {
      const submitURL = 'https://biscuitandchai.com/api/contactform'
      const postData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
        helpTech: this.services,
      }
      const formOptions = {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      fetch(submitURL, formOptions)
        .then(response => {
          if (response.ok) {
            window.location.href = '/thank-you'
          }
        })
        .catch(error => {
          console.error(error)
      })
    },
  },
}
</script>
